import { observable, action, computed } from "mobx";
import { version, ignore } from "mobx-sync";
import TiktokPixel from 'tiktok-pixel';
import TagManager from 'react-gtm-module';
import ReactPixel from 'react-facebook-pixel';
import i18next from "i18next";

@version('2.31')
class IfaceMngr {
    TopMenuItems = ["About", "History", "Terms", "ShopMenu"];
    @observable init = false;
    @ignore @observable activeTopMenu = "ShopMenu";
    
    @ignore @observable isMobile = window.innerWidth < 768;
  
    @action
    updateWindowWidth = () => {
        this.isMobile !== (window.innerWidth < 768) && (this.isMobile = !this.isMobile);
    }
    
    @observable contextMenuVisible = false;

    @ignore @observable WelcomeShopImageModalShownList = [];
    @ignore @observable isWelcomePlusImageModalShown = false;

    @observable i18nextLng = 'he';
    @observable isLngUserDefined = false;

    languageMap = {
        en: { title: "English", label: "Eng", dir: "ltr", active: true},
        ar: { title: "العربية", label: "عر", dir: "rtl", active: true },
        fr: { title: "Français", label: "Fr", dir: "ltr", active: true },
        he: { title: "עברית", label: "עבר", dir: "rtl", active: true},
        ru: { title: "Русский", label: "Рус", dir: "ltr", active: true}
    }
    @computed
    get isRTL() {
        return this.languageMap[this.i18nextLng].dir === 'rtl';
    }
    @action
    setI18nextLng = (lng, isDefault = false, postChange = null) => {
        if((this.isLngUserDefined && isDefault) // don't change lng if user changed it
            || !Object.hasOwn(this.languageMap, lng)) return; 
        this.isLngUserDefined = !isDefault;
        document.body.dir = this.languageMap[lng].dir ?? 'rtl';
        this.i18nextLng = lng;
        i18next.changeLanguage(lng);
        
        
        window.nagishli_config.language = lng === 'he' ? 'he' : 'en';
        // const nagishli = document.querySelector('#nagishli_script'); 
        // nagishli.remove();
        // document.body.appendChild(nagishli);

        const nagishliLangButton = document.querySelector(`#NagishLiLangOptions li[data-nagishli-lang="${window.nagishli_config.language}"]`)
        if(nagishliLangButton) {
            nagishliLangButton.parentElement.click() 
            nagishliLangButton.click()
        }
               
        // don't hide the accessibility button. its ok that it is only in hebrew
        false && setTimeout(()=>{
            let access = document.querySelector('.accessibility_div_wrap');
            if(access) {
                access.style.display = lng === 'he' ? 'block' : 'none';
            } else console.log('access not found');    
        }, 1000);
    }

    setAdditionalResourceBundle = (resourse) => i18next.addResourceBundle('en', 'translation', resourse, true, true);

    resetState = () => {
        //this.setI18nextLng(this.i18nextLng, true);
      
        this.hideContextMenu();
        // this.setActiveTopMenu('About');
    }

    isMenuItemGood = (item) => {
        return true;
    }

    @action
    getActiveTopMenu(){
        return this.activeTopMenu;
    }

    @action
    setActiveTopMenu = (menuSelected) => {
        if(this.isMenuItemGood(menuSelected)){
            this.activeTopMenu = menuSelected;
            // console.log("setActiveTopMenu:", menuSelected);
        } else {
            // console.log("bad menu item:", menuSelected);
        }
    }

    @action
    setInit = () => {
        this.resetState();
        this.init = true;
    }
    @action
    hideContextMenu = () => {
        if(this.contextMenuVisible) setTimeout(() => {
            this.contextMenuVisible = false;
        }, 10);
    }

    @computed
    get pixelsIds() {
        return {
            Facebook: process?.env.REACT_APP_FACEBOOK_PIXEL ?? '',
            TikTok: process?.env.REACT_APP_TIKTOK_PIXEL ?? '',
            Google: process?.env.REACT_APP_GOOGLE_TM ?? '',
        }
    }

    allPixelsInit = () => {
        this.pixelsIds['Google'] && TagManager.initialize({gtmId: this.pixelsIds['Google']});
        this.pixelsIds['TikTok'] && TiktokPixel.init(this.pixelsIds['TikTok']) && TiktokPixel.pageView();
        this.pixelsIds['Facebook'] && ReactPixel.init(this.pixelsIds['Facebook']) && ReactPixel.pageView();
    }

    @action
    setWelcomeShopImageModalShown = (shopId) => {
        this.WelcomeShopImageModalShownList.push(shopId);
    }

    @action
    setWelcomePlusImageModalShown = () => {
        this.isWelcomePlusImageModalShown = true;
    }
}
export default IfaceMngr